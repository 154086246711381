import React from 'react';
import {Alert} from 'reactstrap'
import BG from '../../assets/Rectangle.png';
import CARD_BG from '../../assets/CardBG.png';
import POLYGON from '../../assets/Polygon.png';
import STAR2 from '../../assets/RepeatGrid2.png';
import STAR3 from '../../assets/RepeatGrid3.png';
import STAR5 from '../../assets/RepeatGrid5.png';
import Rupees from '../../assets/Rupees.png';
import BACK from '../../assets/RectangleBack.png';
// import SUBS from '../../assets/SUBSCRIPTION.png';
import { Row,Col,Container,Button,Card} from 'react-bootstrap';
import PaymentGateWay from './PaymentGateWay2';
import {Input} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {  CardHeader, CardFooter, CardBody,
	CardTitle, CardText } from 'reactstrap';

const tawkTo = require("tawkto-react");
const tawkToPropertyId = '5f5b18d5f0e7167d000f56d2'

const useStyles = makeStyles({
	card:{
		'& span':{
			display:'none'
		},
		'&:hover':{
			    '& div':{
					width:'100%',
				},
				'& span':{
					color:'white',
					display:'block',
					fontSize:'1.1rem',
					fontWeight:'800'
				},
				'& card-body':{
					width:'100%'
				},
				color:'#17a2b8!important',
				'& ul':{
				
				},
				'& ul li':{
					color:'black'
				}
		}
	}
})

export default function Pricing(){
	// React.useEffect(() => {
	// 	tawkTo(tawkToPropertyId)
	// }, [])
		
			const classes = useStyles();

	
	const [coupon,setCoupon] = React.useState('');
		const matches = useMediaQuery("(min-width:600px)");
	
		return(     
			<Container fluid style={{backgroundImage:`url(${BG})`,width:matches?'80vw':'100vw',display:'flex',justifyContent:'center',alignItems:'center',marginBottom:'10rem',}} >
	  
			  {/* <img src={SUBS} style={{width:'25%',position:'absolute',top:'85px',backgroundColor:'#f89b1c',borderRadius:'30px',padding:'0.7rem',right:'-15px'}} /> */}
									   
					   
			  <Row style={{marginTop:'70px',width:'100%'}} >
			  {/*  <p>
					This is only for trial purpose .just click on any buy  plan .choose net banking 
					 and you get callback requests and mock tests according the plans.
					<p> Note:No money will be deduced .this is just for trial purpose.</p>
					 </p>
				  */}
				  <Card   style={{width:'90%',margin:'0 auto'}} >
					<CardHeader><h5 style={{textAlign:'center'}}  > &emsp;  First Time Offer for limited period.Use coupon code <span class="badge badge-warning">FIRST50</span></h5></CardHeader>
					<CardBody>
					{/* <CardTitle tag="h5">Special Title Treatment</CardTitle> */}
					<CardText> 
					<p style={{textAlign:'center'}} >&emsp;    At MentoMeet, we provide the best services from top mentors to quality tests at a very reasonable price.
					100s of students have put their trust in MentoMeet because we believe in&emsp;quality over quantity.
					We provide a wide range of subscription plans by keeping in mind the needs of our Students.Try our services once

				    Contact us for any Query regarding payment&emsp; at<a href="mailto:contact@mentomeet.com" style={{color:"#3F5E26"}} target="_blank" className="col-12">contact@mentomeet.com</a> +916378963002</p></CardText>					</CardBody>
					<CardFooter><h5 style={{textAlign:'center'}} >Steps to pay using PhonePe/Gpay- 1.Click 'BUY NOW' 2.Choose 'UPI QR' 3.Click 'PAY' 4.Scan QR from your App </h5></CardFooter>
					<CardFooter><h5 style={{textAlign:'center'}} >&emsp;   You can also contact us, if you need any <span class="badge badge-warning" style={{whiteSpace:'break-spaces'}}  >&emsp;   Financial support in our prices upto 30-50% Discount</span></h5></CardFooter>
      			  </Card>
  				{/* <div class="alert alert-success" role="alert">
				  <h5>First Time Offer for limited period.Use coupon code <span class="badge badge-warning">FIRST50</span></h5>
					<p>At MentoMeet, we provide the best services from top mentors to quality tests at a very reasonable price.
					100s of students have put their trust in MentoMeet because we believe in quality over quantity.
					We provide a wide range of subscription plans by keeping in mind the needs of our Students.Try our services once
				    Contact us for any Query regarding payment at<a href="mailto:contact@mentomeet.com" style={{color:"#3F5E26"}} target="_blank" className="col-12">contact@mentomeet.com</a>
 					or 7310821740</p>
					<h5>You can also contact us, if you need any <span class="badge badge-warning">Financial support/reduction in our prices upto 20-30% Discount</span></h5>
				   
				</div> */}
  				 
				  <Col  className={classes.card} style={{marginTop:'2rem',width:'90%'}}  >
					  <Card style={{maxWidth:'300px'}} >
						<Card.Header style={{backgroundColor:'#f89b1c',height:'auto',borderBottomRightRadius:'40px'}} >
						  <span>FIRST50 </span>
						  <span>TRY NOW LIMITED OFFER</span>
						  <h3>Virtual Internship</h3>
						  <p><img src={STAR2} style={{width:'50px'}} /></p> 
						  <p style={{display:'flex',marginRight:'0' ,textDecoration: 'line-through',width:'20px',height:'25px',marginRight:'10px',marginLeft:'auto' }} >2999</p>
						  <h2 style={{display:'flex',marginRight:'0'}} ><img src={Rupees} style={{width:'20px',height:'25px',marginRight:'10px',marginLeft:'auto'}} />1699</h2>
						</Card.Header>
						<Card.Body style={{width:'100%'}} >
								<h5>6 Weeks certified virtual Internship</h5>
								<ul>
								  <li>Choose any of the following Programs- </li>
								  <li>Web development Project(MERN stack)</li>
								  <li>Mastering Python Programming </li>
								  <li>Get <a href="https://docs.google.com/document/d/1KTmQMmXJZwnJMoAk6HEpzWEJ8fkLgp5N1Mfi7_V8gRo/edit?usp=sharing">More Information</a> here</li>
								</ul>
						  </Card.Body>
						 <Card.Footer style={{width:'100%'}} >
							  <img src={POLYGON} style={{position:'absolute',bottom:'10px',right:'30px',width:'30px'}} />				
							  <Input
								type="text"
								id='coupon-code'
								name='coupon'
								placeholder='Enter Coupon For Availing Discount'
								style={{width:'100%',marginBottom:'1rem'}}
								vaue={coupon}
								onChange={e => setCoupon(e.target.value)}
			
								/>
					  
								 <PaymentGateWay coupon={coupon} amount={169900} PlanId="601b0d137de4551cf96bdb1f" />											 
				
						 </Card.Footer>
					   </Card>
					  
				  </Col>
				  <Col style={{marginTop:'2rem',width:'90%'}} >
					  <Card className={classes.card}  style={{maxWidth:'300px'}} >
						<Card.Header style={{backgroundColor:'#f89b1c',height:'auto',borderBottomRightRadius:'40px'}} >
						  <span>FIRST50 </span>
						  <span>TRY NOW LIMITED OFFER</span>
					
						  <h3>Mock Interviews</h3>
						  <p><img src={STAR2} style={{width:'50px'}} /></p>
						  <p style={{display:'flex',marginRight:'0' ,textDecoration: 'line-through',width:'20px',height:'25px',marginRight:'10px',marginLeft:'auto' }} >2449</p>
						  <h2 style={{display:'flex',marginRight:'0'}} ><img src={Rupees} style={{width:'20px',height:'25px',marginRight:'10px',marginLeft:'auto'}} />999</h2>				  
						  </Card.Header>
						<Card.Body style={{width:'100%'}} >
								<h5>4 Mock interview Meeting to ace your preparation </h5>
								<ul>
									  <li>Meeting on Get your resume reviewed by expert mentors</li>
									  <li>Meeting on Ask your queries from mentor directly</li>
									  <li>Meeting to Convert internship into full time roles</li>
									  <li>Final Meeting on A personalized progress report</li>
									  
								</ul>
						  </Card.Body>
						 <Card.Footer style={{width:'100%'}} >
							  <img src={POLYGON} style={{position:'absolute',bottom:'10px',right:'30px',width:'30px'}} />				
							  <Input
									type="text"
									id='coupon-code'
									name='coupon'
									placeholder='Enter Coupon For Availing Discount'
									style={{width:'100%',marginBottom:'1rem'}}
									vaue={coupon}
									onChange={e => setCoupon(e.target.value)}
										
								/>
								 <PaymentGateWay coupon={coupon} amount={99900}  PlanId="601b0d137de4551cf96bdb20" />											 
				
						 </Card.Footer>
					   </Card>
				  </Col>
				  
				  <Col style={{marginTop:'2rem',width:'90%'}} >
					  <Card className={classes.card} style={{maxWidth:'300px'}} >
						<Card.Header style={{backgroundColor:'#f89b1c',height:'auto',borderBottomRightRadius:'40px'}} >
						  <span>FIRST50 </span>
						  <span>TRY NOW LIMITED OFFER</span>
						  <h3>Talk to mentor</h3>
						  <p><img src={STAR2} style={{width:'50px'}} /></p>
						  <p style={{display:'flex',marginRight:'0' ,textDecoration: 'line-through',width:'20px',height:'25px',marginRight:'10px',marginLeft:'auto' }} >499</p>
						  <h2 style={{display:'flex',marginRight:'0'}} ><img src={Rupees} style={{width:'20px',height:'25px',marginRight:'10px',marginLeft:'auto'}} />199</h2>				  
						  </Card.Header>
						<Card.Body style={{width:'100%'}} >
						        <h5>Get all solutions in 1 call</h5>
								<ul>
								  <li>Start your coding journey with us</li>
								  <li>Cracking the tech interview	</li>
								  <li>Build your preparation strategy for next teach round</li>
								  <li>Convert internship into full time roles</li>
								</ul>
						  </Card.Body>
						 <Card.Footer style={{width:'100%'}} >
							  <img src={POLYGON} style={{position:'absolute',bottom:'10px',right:'30px',width:'30px'}} />				
							  <Input
								type="text"
								id='coupon-code'
								name='coupon'
								placeholder='Enter Coupon For Availing Discount'
								style={{width:'100%',marginBottom:'1rem'}}
								vaue={coupon}
								onChange={e => setCoupon(e.target.value)}
									
							   />
								<PaymentGateWay coupon={coupon} amount={19900} PlanId="601b0d137de4551cf96bdb1e" />											 
				
						 </Card.Footer>
					   </Card>
						  
				  </Col>
			  </Row>
			</Container>	
		  )
	  }
