import Axios from "axios";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import GoogleMeetManager from "../../GoogleMeet";

const MeetForm = ({ clicked, SetClicked, question, user, setQuestions }) => {
  const loggedInUserEmail = JSON.parse(localStorage.getItem("user")).email;
  const toggle = () => {
    SetClicked(!clicked);
    SetLink("");
  };
  const [MeetLink, SetLink] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);

  const getCombinedDateTime = () => {
    if (selectedDate && selectedTime) {
      const combinedDateTime = new Date(selectedDate);
      combinedDateTime.setHours(
        selectedTime.getHours(),
        selectedTime.getMinutes(),
        selectedTime.getSeconds()
      );
      return combinedDateTime;
    }
    return null;
  };

  const handleApprove = (qid) => {
    if (MeetLink === "" || selectedDate === null || selectedTime === null)
      return alert("Please Enter a Link or select time");
    toggle();
    const token = localStorage.getItem("token");
    const mentorName =
      JSON.parse(localStorage.getItem("user")).firstName +
      " " +
      JSON.parse(localStorage.getItem("user")).lastName;
    Axios.put(
      `https://${window.location.hostname}:5005/mentor/allquestions`,
      {
        mentorAttended: user._id,
        Question_id: qid,
        MeetLink: MeetLink,
        selectedTime: getCombinedDateTime(),
        mentorName,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((questions) => {
        setQuestions(questions.data);
      })
      .catch((err) => alert("Already accepted! Please Refresh"));
  };

  const filterValidEmails = (emails) => {
    // Regular Expression for validating email addresses
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Filter valid emails
    return emails.filter((email) => emailRegex.test(email));
  };

  return (
    <div>
      <Modal isOpen={clicked} toggle={toggle}>
        <ModalHeader toggle={toggle}>APPROVAL CONFIRMATION</ModalHeader>
        <ModalBody>
          <p>
            <span style={{ fontWeight: "bold" }}>Mentee Name : </span>{" "}
            {question.menteeName && question.menteeName.toUpperCase()}
          </p>
          <p className="mt-1">
            <strong>Application Date:</strong>{" "}
            {new Date(question?.applicationDate).toDateString()}
          </p>
          <p className="mt-1">
            <strong>Selected Date:</strong>{" "}
            {new Date(question?.selectedDate).toDateString()}
          </p>
          <p className="mt-1">
            <strong>Selected Slot:</strong> {question?.slot}
          </p>
          <p className="mt-1">
            <strong>Details : </strong>
            <span className="text-success">
              {question.query && question.query.toUpperCase()}
            </span>
          </p>

          {/* Date Picker */}
          <div style={{ display: "flexbox", flexWrap: "wrap" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker"
                label="Select Date"
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                format="MM/dd/yyyy"
              />

              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Kindly Select a Meeting Time"
                placeholder="Select Meeting Time within the slot chosen by Mentee"
                value={selectedTime}
                onChange={(date) => setSelectedTime(date)}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </div>
          <div>
            <GoogleMeetManager
              emails={
                filterValidEmails([question?.requestTo, loggedInUserEmail]) ||
                []
              }
              meetingLink={MeetLink}
              setMeetingLink={SetLink}
            />
            <Input
              type="text"
              placeholder="Please Fill Google-Meet Link"
              value={MeetLink}
              onChange={(e) => SetLink(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => handleApprove(question._id)}>
            Confirm Approve
          </Button>{" "}
          <Button color="danger" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MeetForm;
