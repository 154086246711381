import React, { Fragment } from "react";
import ForgotPassword from "./components/Login/ForgotPassword";
import PasswordReset from "./components/Login/PasswordReset";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./css/App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Otp from "./components/Login/Otp";
import BlogDetailedPage from "./components/Blog/BlogDetailedPage.js";
import BlogList from "./components/Blog/BlogList.js";
import BlogLists from "./components/Blog/BlogLists.js";
import MentorLists from "./components/Profile/Mentor/MentorLists";
import MentorCreateForm from "./components/Profile/MentorCreateForm";
import MentorDashboard from "./components/Profile/Mentor/MentorDashboard";
import MyProfile from "./components/Profile/MyProfile/MyProfile.js";
import OtherProfile from "./components/Profile/OtherProfile/OtherProfile.js";
import MenteeCreateForm from "./components/Mentee/MenteeCreateForm";
import MenteeHistory from "./components/Mentee/MenteeHistory";
import BlogCreateForm from "./components/Blog/BlogCreateForm";
import BlogDetail from "./components/Blog/BlogDetail";
import AboutUs from "../src/components/About";

import CreateMockTest from "./components/MockTest/CreateMockTest";

import MyAllTestsHistory from "./components/MockTest/History/MyAllTestsHistory";
import MockTest from "./components/MockTest/Mentee/MockTest";
import MenteeTestHistory from "./components/MockTest/Mentee/History/MenteeTestHistory";
import MentorHomePage from "./components/Mentor/index.js";

import WebinarRegistration from "./components/webinar/WebinarRegistration";

import StudyMaterial from "./components/StudyMaterial/App";
import UploadNotes from "./components/StudyMaterial/uploadNotes";
import NotesPanel from "./components/StudyMaterial/NotesPanel";

import Index from "./components/index.js";
import Question from "./components/Question/Question.js";
import Answer from "./components/Answer/Answer";
import Login from "./components/Login/Login";
import Chat from "./components/Chat/Chat/Chat.js";
import Join from "./components/Chat/Join/Join.js";
import NavBar from "./components/NavBar";
import AdminPage from "./components/admin/AdminPage";
import AdminDashboardPage from "./components/admin/AdminDashboardPage";
import Footer from "./components/Footer";
import Mentor from "./components/Team";
import Pricing from "./components/Pricing/ResponsivePricing";
import CollegePricing from "./components/Pricing/CollegePricing";
import MedicalPricing from "./components/Pricing/MedicalPricing";

import ScheduleMeetingPage from "./components/Profile/Mentor/ScheduleMeetingPage";
import CollaborationPage from "./components/Collaboration/App";
import PromotioPage from "./components/Promotion/index";
import Ticker from "./components/Ticker/index.js";
import Sidebar from "./components/Sidebar/index.js";

function App() {
  let [renderFooter, setRenderFooter] = React.useState(true);

  // check is user logged in or not through local storage
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  React.useEffect(() => {
    const spinner = document.getElementById("spinner");

    if (spinner && !spinner.hasAttribute("hidden")) {
      spinner.setAttribute("hidden", "true");
      spinner.setAttribute("display", "none");
    }
    if (renderFooter) return;
    setTimeout(() => {
      setRenderFooter(true);
    }, 2000);
  }, []);

  const getRoutes = () => {
    return (
      <div>
        <Switch>
          {/* blogs,mentors,mentees */}
          <Route
            path="/scheduleMeeting"
            render={(props) => <ScheduleMeetingPage {...props} />}
          />
          <Route
            path="/collaboration"
            render={(props) => <CollaborationPage {...props} />}
          />

          <Route
            path="/promotion"
            render={(props) => <PromotioPage {...props} />}
          />
          <Route
            path="/studyMaterials"
            render={(props) => <StudyMaterial {...props} />}
          />
          <Route
            path="/promotion"
            render={(props) => <PromotioPage {...props} />}
          />

          <Route
            path="/blogdetail"
            render={(props) => <BlogDetailedPage {...props} />}
          />
          <Route
            path="/blogs/view/:bid"
            render={(props) => <BlogDetail {...props} />}
          />
          <Route path="/blogs" render={(props) => <BlogLists {...props} />} />
          <Route path="/bloglist" render={(props) => <BlogList {...props} />} />

          {/* <Route path="/createblog" render={props => <BlogCreateForm {...props} /> } /> */}
          <Route
            path="/mentors"
            render={(props) => <MentorLists {...props} />}
          />
          <Route
            path="/mentor/dashboard"
            render={(props) => <MentorDashboard {...props} />}
          />
          <Route
            path="/mentor/createtest"
            render={(props) => <CreateMockTest {...props} />}
          />
          <Route
            path="/mentor/history/testhistory"
            render={(props) => <MyAllTestsHistory {...props} />}
          />
          <Route
            path="/mentor/history/studyMaterial"
            render={(props) => <NotesPanel {...props} />}
          />
          <Route
            path="/mentor/upload/studyMaterial"
            render={(props) => <UploadNotes {...props} />}
          />
          <Route
            exact
            path="/mentor"
            render={(props) => <MentorHomePage {...props} />}
          />

          <Route
            exact
            path="/mentor/create"
            render={(props) => <MentorCreateForm {...props} />}
          />

          {/* Using the same  Profile component, with different options changed in the file and it Myprofile and other profile */}
          <Route
            path="/profile/:id"
            render={(props) => <OtherProfile {...props} />}
          />
          <Route path="/profile" render={(props) => <MyProfile {...props} />} />
          {/* Pricing*/}
          <Route path="/pricing" render={(props) => <Pricing {...props} />} />
          <Route
            path="/plans"
            render={(props) => <CollegePricing {...props} />}
          />
          <Route
            path="/medicalPlans"
            render={(props) => <MedicalPricing {...props} />}
          />

          {/* Added Route For Mentee History Page  */}
          <Route
            path="/mentee/dashboard"
            render={(props) => <MenteeHistory {...props} />}
          />
          <Route
            path="/mentee/mocktest/history"
            render={(props) => <MenteeTestHistory {...props} />}
          />
          <Route
            path="/mentee/mocktest"
            render={(props) => <MockTest {...props} />}
          />
          <Route
            path="/mentee"
            render={(props) => <MenteeCreateForm {...props} />}
          />

          {/* <Route path="/qna" render={props => <Question {...props} /> } /> */}
          <Route exact path="/Otp" render={(props) => <Otp {...props} />} />
          <Route path="/index" render={(props) => <Index {...props} />} />
          <Route path="/qna" render={(props) => <Question {...props} />} />
          <Route path="/answer/:id" render={(props) => <Answer {...props} />} />
          <Route exact path="/login" render={(props) => <Login {...props} />} />
          <Route
            exact
            path="/register"
            render={(props) => <Login {...props} isRegistering />}
          />
          <Route
            exact
            path="/forgotpassword"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path="/reset/:token"
            render={(props) => <PasswordReset {...props} />}
          />
          <Route path="/join" render={(props) => <Join {...props} />} />
          <Route path="/chat" render={(props) => <Chat {...props} />} />
          {/* <Route path="/login" render={props => <Login {...props} /> } />
           <Route path="/student" render={props => <Student {...props} /> } /> */}
          <Route
            path="/webinar"
            render={(props) => <WebinarRegistration {...props} />}
          />
          {/* Added Route For Admin Page  */}
          <Route
            path="/admin/dashboard/mocktest"
            render={(props) => <AdminDashboardPage {...props} />}
          />
          <Route
            path="/admin/dashboard/studymaterial"
            render={(props) => <AdminDashboardPage {...props} />}
          />
          <Route
            path="/admin/dashboard/webinar"
            render={(props) => <AdminDashboardPage {...props} />}
          />
          <Route
            path="/admin/dashboard"
            render={(props) => <AdminDashboardPage {...props} />}
          />
          <Route
            exact
            path="/admin"
            render={(props) => <AdminPage {...props} />}
          />
          <Route
            exact
            path="/about-us"
            render={(props) => <AboutUs {...props} />}
          />

          <Route path="/team" render={(props) => <Mentor {...props} />} />

          <Redirect to="/index" />
          <Redirect from="/" to="/index" />
        </Switch>
      </div>
    );
  };

  const getContent = () => {
    // If user is logged in show thw sidebar
    if (user) {
      return (
        <Sidebar>
           {/* <NavBar /> */}
          {getRoutes()}
          {/* Footer Section */}
          {/* <Footer /> */}
        </Sidebar>
      );
    }

    // else show him the navbar and ticker
    return (
      <>
        <Ticker />
        <NavBar />
        {getRoutes()}
        {/* Footer Section */}
        {renderFooter ? <Footer /> : null}
      </>
    );
  };

  return (
    <Fragment>
      <BrowserRouter>
        {/* Header */}
        {getContent()}
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
