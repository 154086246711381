import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";

import brand from "../../assets/brand.png";

import { useHistory, useLocation } from "react-router-dom";
import { ListItemSecondaryAction, ListItemButton } from "@material-ui/core";

// Import Material-UI Icons
import PeopleIcon from "@material-ui/icons/People";
import EventIcon from "@material-ui/icons/Event";
import BookIcon from "@material-ui/icons/Book";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer"; // QnA
import DescriptionIcon from "@material-ui/icons/Description"; // Blogs
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn"; // Mock Test
import MenuBookIcon from "@material-ui/icons/MenuBook"; // Study Material
import DashboardIcon from "@material-ui/icons/Dashboard";
import AddBoxIcon from "@material-ui/icons/AddBox";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import CreateIcon from "@material-ui/icons/Create";
import HistoryIcon from "@material-ui/icons/History";
import SchoolIcon from "@material-ui/icons/School";
import HomeIcon from "@material-ui/icons/Home";
import Footer from "../Footer";
import ChromeReaderMode from "@material-ui/icons/ChromeReaderMode";

const drawerWidth = 250;

// material ui css
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "white",
    color: "black",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logout: {
    marginTop: "auto", // Pushes the logout button to the bottom
  },
  listItem: {
    "&.Mui-selected": {
      backgroundColor: "#ff9500", // Active tab background color
      color: "#000", // Active tab text color
      "& .MuiListItemIcon-root": {
        color: "#000", // Active tab icon color
      },
    },
    "&:hover": {
      backgroundColor: "#fff", // Hover background color
      color: "#ff9500",
    },
  },
}));

export default function Sidebar({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false); // keep drawer open by default on initial load

  const history = useHistory(); // provide navigation
  const location = useLocation(); // provide location of browser path

  const handleNavigation = (path) => {
    setOpen(false);
    history.push(path); // Redirect to the path
  };

  // getting user details
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    // remove user info from local storage
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    // redirect user to home page
    window.location.href = "/index";
  };

  // Mentor Menu Items
  const mentorMenuItems = [
    { text: "Dashboard", icon: <DashboardIcon />, path: "/mentor/dashboard" },
    { text: "QnA", icon: <QuestionAnswerIcon />, path: "/qna" },
    { text: "Blogs", icon: <DescriptionIcon />, path: "/blogs" },
    { text: "Create Test", icon: <AddBoxIcon />, path: "/mentor/createtest" },
    {
      text: "My Mock Tests",
      icon: <LibraryBooksIcon />,
      path: "/mentor/history/testhistory",
    },
    { text: "Upload Notes", icon: <MenuBookIcon />, path: "/mentor/upload/studyMaterial" },
    { text: "My Notes", icon: <ChromeReaderMode />, path: "/mentor/history/studyMaterial" },
    { text: "Event", icon: <EventIcon />, path: "/webinar" },
    { text: "Find Your Mentor", icon: <PeopleIcon />, path: "/mentors" },
    {
      text: "Schedule a Meeting",
      icon: <EventIcon />,
      path: "/scheduleMeeting",
    },

    { text: "Back To Website", icon: <HomeIcon />, path: "/index" },
  ];

  // Mentor Secondary menu items
  const mentorSecondaryMenuItems = [
    { text: "Profile", icon: <AccountCircleIcon />, path: "/profile" },
  ];

  const getMentorListActions = () => {
    return (
      <>
        {/* Main Menu */}
        <List>
          {mentorMenuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              selected={location.pathname === item.path}
              onClick={() => handleNavigation(item.path)}
              className={classes.listItem}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>

        <Divider />

        {/* Secondary Menu */}
        <List>
          {mentorSecondaryMenuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              selected={location.pathname === item.path}
              className={classes.listItem}
              onClick={() => {
                handleNavigation(item.path);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>

        {/* Logout button at the bottom */}
        <List className={classes.logout}>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </>
    );
  };

  // Mentee Menu Items
  const menteeMenuItems = [
    { text: "Dashboard", icon: <DashboardIcon />, path: "/mentee/dashboard" },
    { text: "Find Your Mentor", icon: <PeopleIcon />, path: "/mentors" },
    {
      text: "Schedule a Meeting",
      icon: <EventIcon />,
      path: "/scheduleMeeting",
    },
    { text: "QnA", icon: <QuestionAnswerIcon />, path: "/qna" },
    { text: "Blogs", icon: <DescriptionIcon />, path: "/blogs" },
    {
      text: "Mock Test",
      icon: <AssignmentTurnedInIcon />,
      path: "/mentee/mocktest",
    },
    {
      text: "Mock Test History",
      icon: <HistoryIcon />,
      path: "/mentee/mocktest/history",
    },
    { text: "Study Material", icon: <MenuBookIcon />, path: "/studyMaterials" },
    { text: "Event", icon: <EventIcon />, path: "/webinar" },
  ];

  // Mentee Secondary menu items
  const menteeSecondaryMenuItems = [
    { text: "Profile", icon: <AccountCircleIcon />, path: "/profile" },
    { text: "Engineering College", icon: <SchoolIcon />, path: "/plans" },
    { text: "JEE/NEET", icon: <MenuBookIcon />, path: "/pricing" },
    { text: "Back To Website", icon: <HomeIcon />, path: "/index" },
  ];

  const getMenteeListActions = () => {
    return (
      <>
        {/* Main Menu */}
        <List>
          {menteeMenuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              selected={location.pathname === item.path}
              onClick={() => handleNavigation(item.path)}
              className={classes.listItem}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>

        <Divider />

        {/* Secondary Menu */}
        <List>
          {menteeSecondaryMenuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              selected={location.pathname === item.path}
              className={classes.listItem}
              onClick={() => {
                handleNavigation(item.path);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>

        {/* Logout button at the bottom */}
        <List className={classes.logout}>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </>
    );
  };

  // It it wil decide wthether to show mentee or mentor actions
  const getUserActions = () => {
    if (user?.role == "Mentor") {
      return getMentorListActions();
    } else {
      return getMenteeListActions();
    }
  };

  const getHeaderTitle = () => {
    return <Typography>Profile</Typography>;
  };

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className="mentomeetLogo" style={{ padding: "10px" }}>
          <a
            href="/index"
            className="d-flex justify-content-center align-items-center"
          >
            <img src={brand} width={30} height={30} alt="Brand" />
          </a>
          {/* <nav className="navbar navbar-toggler-container shadow navbar-expand-lg sticky-top">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#mobileNavigation"
            >
              <MenuIcon />
            </button>
          </nav> */}
        </div>
        {getUserActions()}
      </Drawer>
      <div className="container-fluid p-0 m-0">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "60px",
            width: "100%",
            borderBottom: "1px solid #5a159e",
            padding: "5px 10px",
          }}
        >
          <h5
            style={{
              fontSize: "20px",
              display: "inline",
              fontFamily: "poppins",
              fontWeight: "bold",
              paddingLeft: 8,
              textDecoration: "none",
              color: "#5a159e",
              background: "linear-gradient(to left, #ff9500, #5a159e)",
              "-webkit-background-clip": "text",
              "-webkit-text-fill-color": "transparent",
            }}
          >
            MentoMEET
          </h5>
          <h4 style={{ fontWeight: "bolder" }}>
            Hi, {user?.firstName} {user?.lastName}{" "}
          </h4>
        </div>
        <div style={{ minHeight: "100vh" }}>{children}</div>
        <Footer />
      </div>
    </div>
  );
}
