import React, { useEffect, useState } from "react";
import { gapi } from "gapi-script"; // Import gapi

const GoogleCalendarButton = ({ id, name, description, startTime }) => {
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const SCOPE = "https://www.googleapis.com/auth/calendar.events";

  let tokenClient;
  let accessToken;

  useEffect(() => {
    // Initialize the gapi client using gapi-script
    const initGapiClient = async () => {
      try {
        await gapi.load("client:auth2", () => {
          gapi.client
            .init({
              apiKey: API_KEY,
              clientId: CLIENT_ID,
              scope: SCOPE,
              discoveryDocs: [
                "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
              ],
            })
            .then(() => {
              console.log("GAPI Client Initialized");
            });
        });
      } catch (error) {
        console.error("Error initializing GAPI client:", error);
      }
    };

    initGapiClient();
  }, []);

  const handleAuthClick = () => {
    const authInstance = gapi.auth2.getAuthInstance();

    if (!authInstance.isSignedIn.get()) {
      authInstance.signIn().then(() => {
        addEvent();
      });
    } else {
      addEvent();
    }
  };


  const addEvent = async () => {
    const startAt = new Date(startTime).toISOString();
    const endAt = new Date(startTime + 60 * 60 * 1000).toISOString(); //  1-hour meeting time
    console.log("startAt",startAt)
    console.log("endAt",endAt)
    const event = {
      summary: name || "Untitled Event",
      description: description || "No Description",
      start: {
        dateTime: startAt,
        timeZone: "Asia/Kolkata",
      },
      end: {
        dateTime: endAt,
        timeZone: "Asia/Kolkata",
      },
    };

    try {
      const response = await gapi.client.calendar.events.insert({
        calendarId: "primary",
        resource: event,
      });

      if (response.status === 200) {
        alert("Event added successfully to your calendar.");
      } else {
        console.error("Error creating event:", response);
        alert("Failed to add event. Please try again later.");
      }
    } catch (error) {
      console.error("Error adding event to calendar:", error);
      alert("An error occurred while adding the event.");
    }
  };

  return (
    <button type="button" className="btn" onClick={handleAuthClick}>
      <i className="fa-solid fa-calendar"></i>
    </button>
  );
};

export default GoogleCalendarButton;
