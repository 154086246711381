import React from "react";
import Mentors from "../Mentors";
import "./MentorList.css";
import Ajay from '../../../assets/members/ajay.jpg';

// import abhik from "../../../assets/members/abhik.jpeg";
// import rathod from "../../../assets/members/rathod.jpeg";
import Rashi from "../../../assets/members/Rashi.jpg";
// import saurabh from "../../../assets/members/saurabh.jpg";
// import nitesh from "../../../assets/members/nitesh.jpg";
import Abhishek from "../../../assets/members/abhishek-iit.png";
import Tanu from "../../../assets/members/tanu.png";
import rudra from "../../../assets/members/rudra.jpg";
import { Line } from "react-chartjs-2";
// import rohit from "../../../assets/members/rohitmalawat.jpeg";

const mentors = [
  { name: "Rudraksh Vyas", category: "NEET", title: "JLN AJMER" , imgUrl: rudra, link:"5ff1f6e9344b955e194969cb"},
  { name: "Rashi Verma", category: "NEET", title: "IMS BHU" , imgUrl: Rashi, link:"6768eb19ceaa9f40815e971c"},
  { name: "Tanu Sharma", category: "JEE", title: "IIT Kharagpur" , imgUrl: Tanu, link:"658653d589904616d05ee693"},
//   { name: "Shivani", category: "NEET", title: "Expert",imgUrl: rudra },
//   { name: "Harshita", category: "JEE", title: "Mentor", imgUrl: rudra },
  { name: "Abhishek Kumar", category: "JEE", title: "IIT Roorkee" ,imgUrl: Abhishek, link:"6018fc0ef2325d1fad7e5259"},
  { name: "Ajay", category: "PLACEMENT", title: "IIT Roorkee" , imgUrl: Ajay, link:"6031019112d5717ce3f44233"},
];

function MentorLists() {
  return (
    <>
      <Mentors />
      <div className="top-mentors-container">
        <div className="top-mentors-body">
          <h2 className="title">
            Top Mentors Making an{" "}
            <span style={{ color: "#FF9500" }}>Impact</span>
          </h2>
          <p className="sub-title">
            Meet our <span style={{ color: "#FF9500" }}>Dedicated Mentors</span>,
            who are experts in their fields, committed to helping students achieve their goals.
          </p>
          <div className="mentor-card-container">
            {mentors.map((mentor) => (
              <div key={mentor.name}>
                <a
                  href={`/profile/${mentor.link.toLowerCase()}`}
                  className="mentor-card-link"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <div className="mentor-card">
                    <div className="orange"></div>
                    <img src={mentor.imgUrl} alt={mentor.name} />
                    <p className="mentor-name">{mentor.name}</p>
                    <p className="mentor-role">{mentor.title}</p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}


export default MentorLists;
