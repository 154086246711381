import Axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Badge } from "reactstrap";
import { Redirect } from "react-router-dom";
import MeetForm from "./MeetForm";
import { useHistory } from "react-router-dom";
import classNames from "classnames";


import "../Mentor/MentorDashboard.css";

const MentorDashboard = () => {
  const history = useHistory();

  const [Questions, setQuestions] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [approved, setApproved] = useState(false);
  const [clicked, SetClicked] = useState(false);
  const [question, SetQuestion] = useState("");
  const color = {
    JEE: "warning",
    NEET: "info",
    CAREER: "success",
  };

  const QuestionGetter = (path) => {
    let userID = JSON.parse(localStorage.getItem("user"))._id;
    Axios.get(
      `https://${window.location.hostname}:5005/mentor/${path}/${userID}`
    )
      .then((res) => {
        setQuestions(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("user"))) return;
    let category = JSON.parse(localStorage.getItem("user")).category;
    QuestionGetter(`allquestions/${category}`);
  }, []);

  if (JSON.parse(localStorage.getItem("user")) === undefined) {
    return <Redirect to="/" />;
  }

  const handleApprove = (q) => {
    SetQuestion(q);
    SetClicked(!clicked);
  };

  const gotoApprove = () => {
    setApproved(!approved);
    setQuestions([]);
    let category = JSON.parse(localStorage.getItem("user")).category;
    if (!approved) QuestionGetter("approvedqns");
    else QuestionGetter(`allquestions/${category}`);
  };

  return (
    <div className="mentor-dashboard">
      <ToastContainer position="bottom-left" />
      {/* Main Content */}
      <div className="main-content">
        <div className="cta-buttons my-3">
          <Button
            className={classNames('btn',{ 'btn-primary' : approved, 'btn-secondary': !approved})}
            onClick={() => {
            gotoApprove()
            }}
          >
            History
          </Button>
          {/* <Button
            className="schedule-meeting-btn"
            onClick={() => {
              history.push("/scheduleMeeting");
            }}
          >
            Schedule a Meeting */}
          {/* </Button> */}
        </div>
        <MeetForm
          clicked={clicked}
          SetClicked={SetClicked}
          user={user}
          question={question}
          setQuestions={setQuestions}
        />
        <Row className="question-list">
          {Questions.map((question, index) => (
            <Col key={index} md={4}>
              <Card className="question-card">
                <Card.Header>
                  <Badge color={color[question.category]}>
                    {question.category}
                  </Badge>{" "}
                  {question.headline}
                </Card.Header>
                <Card.Body>
                  <p>
                    <strong>Mentee Name:</strong>{" "}
                    {question.menteeName.toUpperCase()}
                  </p>
                  <p>
                    <strong>Application Date:</strong>{" "}
                    {new Date(question?.applicationDate).toLocaleString(
                      "en-IN",
                      {
                        timeZone: "Asia/Kolkata",
                      }
                    )}
                  </p>
                  <p>
                    <strong>Selected Date:</strong>{" "}
                    {new Date(question?.selectedDate).toDateString()}
                  </p>
                  <p>
                    <strong>Selected Slot:</strong> {question?.slot}
                  </p>
                  <Button
                    className="details-btn"
                    onClick={() => handleApprove(question)}
                  >
                    {approved ? "View Details" : "Approve"}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default MentorDashboard;
