import React, { useEffect, useState } from "react";
import { gapi } from "gapi-script";

const GoogleMeetManager = ({ emails, meetingLink, setMeetingLink }) => {
  const CLIENT_ID =process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const DISCOVERY_DOC =
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";
  const SCOPES = "https://www.googleapis.com/auth/calendar.events";

  const initClient = () => {
    gapi.load("client:auth2", () => {
      gapi.client
        .init({
          apiKey: API_KEY, // Replace with your API Key
          clientId: CLIENT_ID, // Replace with your Client ID
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
          ],
          scope: "https://www.googleapis.com/auth/calendar.events",
        })
        .then(() => {
          console.log("GAPI client initialized");
        })
        .catch((error) => {
          console.error("Error initializing GAPI client:", error);
        });
    });
  };

  useEffect(() => {
    initClient();
  }, []);

  const handleAuthClick = () => {
    gapi.load("client:auth2", () => {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: [DISCOVERY_DOC],
          scope: SCOPES,
        })
        .then(() => {
          gapi.auth2.getAuthInstance().signIn();
        });
    });
  };

  const createGoogleMeet = async () => {
    handleAuthClick();
    try {
      // List of email to whom meeting link will be shared
      const attendees = [{ email: "officialmentomeet@gmail.com" }];
      emails.forEach((email) => attendees.push({ email }));

      const event = {
        summary: "Mentorship Meeting",
        start: {
          dateTime: new Date().toISOString(),
          timeZone: "America/Los_Angeles",
        },
        end: {
          dateTime: new Date(
            new Date().getTime() + 60 * 60 * 1000
          ).toISOString(),
          timeZone: "America/Los_Angeles",
        },
        conferenceData: {
          createRequest: {
            requestId: "random-string",
            conferenceSolutionKey: { type: "hangoutsMeet" },
          },
        },
        attendees,
      };

      try {
        const response = await gapi.client.calendar.events.insert({
          calendarId: "primary",
          resource: event,
          sendUpdates: "all",
          conferenceDataVersion: 1,
        });
        const meetLink = response.result.hangoutLink;
        setMeetingLink(meetLink);
      } catch (error) {
        console.error("Error creating event:", error);
      }
    } catch (error) {
      console.error("Error creating meeting:", error);
    }
  };

  return (
    <button className="btn btn-link ml-0 pl-0" onClick={createGoogleMeet}>
      Create Google Meet
    </button>
  );
};

export default GoogleMeetManager;
