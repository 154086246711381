import React, { Component } from "react";
import axios from "axios";
import "./MenteeHistory.css"; // Style file to replicate the design
import GoogleCalendarButton from "../GoogleCalendar";
import GoogleMeetManager from "../GoogleMeet";

const HistoryCard = ({ data }) => {
  const {
    status,
    category,
    applicationDate,
    selectedDate,
    selectedTime,
    MeetLink,
    query,
    slot,
  } = data;

  return (
    <div
      className={`history-card ${
        status === "approved" ? "approved" : "pending"
      }`}
    >
      <div className="card-header">
        <span className={`status-badge ${status}`}>
          {status === "approved" ? "Approved" : "Pending"}
        </span>
        {selectedTime ? (
          <GoogleCalendarButton
            id={applicationDate}
            name={"Meeting Alert"}
            description={`Your meeting is scheduled , your meeting link is ${MeetLink}`}
            startTime={new Date(selectedTime).getTime()}
          />
        ) : null}
      </div>
      <div className="card-body">
        <p>
          <strong>Category:</strong> {category}
        </p>
        <p>
          <strong>Application Date:</strong>{" "}
          {new Date(applicationDate).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })}
        </p>
        <p>
          <strong>Selected Date:</strong>{" "}
          {new Date(selectedDate).toDateString("en-IN")}
        </p>
        <p>
          <strong>Selected Slot:</strong> {slot}
        </p>
        {status === "pending" ? (
          <p className="pending-message">We will reach out to you soon!</p>
        ) : (
          <>
            {selectedTime && (
              <p>
                <strong>Meeting Time:</strong>{" "}
                {(new Date(selectedTime).getHours() % 12 || 12).toString()}:
                {new Date(selectedTime)
                  .getMinutes()
                  .toString()
                  .padStart(2, "0")}{" "}
                {new Date(selectedTime).getHours() >= 12 ? "PM" : "AM"}
              </p>
            )}
            {MeetLink && (
              <div>
                <a
                  href={MeetLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="meet-link"
                >
                  Join Meeting
                </a>
              </div>
            )}
          </>
        )}
        <p className="mt-2">
          <strong>My Query:</strong> {query}
        </p>
      </div>
    </div>
  );
};

class MenteeHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    const menteeID = JSON.parse(localStorage.getItem("user"))._id;
    axios
      .get(
        `https://${window.location.hostname}:5005/mentee/history/${menteeID}`
      )
      .then((response) => {
        this.setState({ data: response.data });
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  }

  renderDataList() {
    return this.state.data.map((item, index) => (
      <HistoryCard data={item} key={index} />
    ));
  }

  render() {
    return (
      <div className="mentee-history-container">
        {/* Main Content */}
        <div className="main-content">
          <h2 className="page-title">My History</h2>
          {this.state.data.length > 0 ? (
            <div className="history-list">{this.renderDataList()}</div>
          ) : (
            <p className="no-history">
              You have not sent any callback requests to the mentors yet.
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default MenteeHistory;
