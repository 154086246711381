import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Badge,
  Button,
  Modal,
} from "react-bootstrap";
import Axios from "axios";
import PaymentHistory from "./PaymentHistory";
const Subscriptions = ({ myBenefits, user_id }) => {
  console.log(myBenefits);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Color = {
    999: "#383CC1",
    399: "#3944F7",
    249: "#46B2E0",
    0: "orange",
  };
  const plans = {
    999: "90 Days Plan",
    399: "30 Days Plan",
    249: "15 Days Plan",
    0: "Free plan",
  };
  const handleClick = () => {};
  return (
    <Container>
      <Row style={{ marginTop: 20 }}>
        <Col>
          <Card style={{ height: "400px", marginBottom: "50px" }}>
            <Card.Header
              style={{ fontWeight: "bold", paddingBottom: 25, left: 0 }}
            >
              My Subscriptions{" "}
              <Button
                variant="primary"
                onClick={handleShow}
                style={{right: 20 }}
              >
                Payment History
              </Button>
            </Card.Header>
            <Card.Body style={{ height: "500px", overflow: "scroll" }}>
              {myBenefits.map((benefit, index) => (
                <Card key={index} style={{ marginBottom: "10px" }}>
                  <Card.Text>
                    <Badge
                      style={{
                        backgroundColor: Color[benefit.price],
                        color: "white",
                        marginRight: "70%",
                        marginTop: 20,
                      }}
                    >
                      {plans[benefit.price]}
                    </Badge>
                    <h4>Benefits Recieved </h4>
                    <p style={{ fontSize: 20 }}>
                      MockTests:{benefit.mocktest} Callbacks:{benefit.callback}
                    </p>
                  </Card.Text>
                  <Card.Text>
                    <h4>My Usage </h4>
                    <p style={{ fontSize: 20 }}>
                      MockTests:{benefit.myUsage.mocktest} Callbacks:
                      {benefit.myUsage.callback}
                    </p>
                    <p style={{ color: "green" }}>
                      Activated on {new Date(benefit.start).toLocaleString()}
                    </p>
                    <p style={{ color: "red" }}>
                      Expire on {new Date(benefit.end).toLocaleString()}
                    </p>
                  </Card.Text>
                </Card>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Payment History</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "500px", overflowX: "scroll" }}>
            <PaymentHistory user_id={user_id} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Close
            </Button>
            {/* <Button variant="primary"></Button> */}
          </Modal.Footer>
        </Modal>
      </>
    </Container>
  );
};
export default Subscriptions;
